<template>
  <td>
    <div :class="class_text" v-if="datas[index]">
      {{ datas[index] | number }}{{ symbol }}
    </div>
  </td>
</template>

<script>
import moment from "moment";

export default {
  name: "Card",
  props: {
    class_text: {
      type: String,
      default: "text-right",
    },
    index: {
      type: String,
      default: "",
    },
    symbol: {
      type: String,
      default: "",
    },
    datas: {
      type: Object,
      default: () => {},
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({}),
};
</script>
